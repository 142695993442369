<template>
	<a-select
		v-model:value="selectValue"
		show-search
		placeholder="请选择行业"
		:filter-option="filterOption"
		:options="options"
		style="width: 100%"
		:disabled="props.diasbled"
		@change="handleChange"
	/>
</template>
<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue"
const props = defineProps({
	diasbled: Boolean,
	class: {
		type: String,
		default: "",
	},
	mode: {
		type: String,
		default: "",
	},
	first: {
		type: Boolean,
		default: false,
	},
})

const emits = defineEmits(["changeValue"])
const selectValue = ref(null)
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"

const options = ref([])
const tradeList = ref([])
const store = useStore()
onMounted(() => {
	if (props.mode == "multiple") {
		selectValue.value = []
	}
	getList()
})

const handleChange = (value) => {
	let params = null
	if (props.mode == "multiple") {
		params = [...value]
	} else {
		let data = tradeList.value.find((item) => item.code === value)
		params = data
	}
	emits("changeValue", params)
}

const getList = () => {
	let data = {
		merchantCode: store.getters["user/merchantCode"],
		status: 1,
	}
	axios.post(api.getListTrade, data).then((res) => {
		console.log(res)
		if (res) {
			tradeList.value = res
			let arr = []
			res.forEach((item) => {
				arr.push({
					label: item.name,
					value: item.code,
				})
			})
			options.value = arr
		} else {
			options.value = []
		}
	})
}

const filterOption = (input, option) => {
	return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
</script>
